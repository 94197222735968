import React, {Component, Fragment, useContext, useState} from 'react';
import BlockTitle from "../_BlockTitle";
import classNames from 'classnames';
import styles from './styles/HTMLBlock.module.scss';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import {action, runInAction} from 'mobx';
import {StoreContext} from "../../../../stores/StoreLoader";
import WatsonApi from "../../../../backends/WatsonApi";
import NotificationManager from "../../../notifications/NotificationManager";
import {EditDropDownItem} from "../EditDropDown";
import FontAwesome from "../../../utilities/FontAwesome";
import SaveCancelButtons from "../SaveCancelButtons";

const htmlBlockContainerClassName = classNames({
    [styles.htmlBlockContainer]: true,
});

const HtmlTerrace = observer((props: {
    blockObj: IHTmlTerrace,
    aboveTheFold: boolean,
}) => {
    const {gridStore} = useContext(StoreContext);

    const [code, setCode] = useState(props.blockObj.json_data?.html?.code || "");
    const [editMode, setEditMode] = useState(false);

    const handleSave = async () => {
        try {
            const client = await WatsonApi();
            const response = await client.apis.organizations.organizations_terraces_partial_update({
                organization_pk: props.blockObj.organization_id,
                id: props.blockObj.id,
                data: {
                    json_data: {
                        html: {
                            code,
                        },
                    },
                },
            })
            setEditMode(false);
            runInAction(() => {
                const updatedTerrace = JSON.parse(response.data);
                gridStore.blocks.set(updatedTerrace.id, updatedTerrace);
            });
            NotificationManager.success("Terrace successfully saved!");
        } catch (e) {
            setCode(props.blockObj.json_data?.html?.code || "");
            NotificationManager.error("Something went wrong! Please try again.");
        }
    }

    const _setEditMode = async (value, cancel) => {
        if (value) {
            setEditMode(value);
        } else if (!cancel) {
            await handleSave();
        }
        else if (cancel) {
            setCode(props.blockObj.json_data?.html?.code || "");
            setEditMode(false);
        }
    }

    const htmlTerraceDropDownItems: EditDropDownItem[] = [
        {
            title: 'Edit HTML',
            icon: <FontAwesome prefix={'fas'} fixedWidth className="fa-pencil-alt"/>,
            onClick: () => _setEditMode(!editMode, true),
        },
    ]

    return (
        <article className={htmlBlockContainerClassName}>
            <BlockTitle
                blockObj={props.blockObj}
                editing={editMode}
                setEditing={_setEditMode}
                aboveTheFold={props.aboveTheFold}
                dropDownItems={htmlTerraceDropDownItems}
                allowSave={true}
            />
            {editMode ? <Fragment>
                <div>
                    <p>Enter Custom HTML below:</p>
                </div>
                    <textarea onChange={e => setCode(e.target.value)} value={code}/>
                    <SaveCancelButtons editMode={editMode} setEditing={_setEditMode}/>
                </Fragment> :
                <div
                    dangerouslySetInnerHTML={{'__html': code}}/>}
        </article>
    );
})

export default HtmlTerrace;
